import { camelCase, getSegmentField } from ".";

export const handleChangeCol = (arr1, arr2) => {
    const colMetrics = arr1.filter((element1) => {
         if (arr2 && arr2.length > 0) {
              return arr2.some((element2) => element2.label === element1.label);
         }
         return false;
    });
    return colMetrics;
};

export const handleChangeColSort = (arr1, arr2) => {
    const metricMap = new Map(arr1.map((item) => [item.key, item]));
    return arr2.map((filterItem) => metricMap.get(filterItem.value.split('_').pop())).filter((item) => item !== undefined);
};

export const handleChangeColSortDimension = (arr1, arr2) => {
     const metricMap = new Map(arr1.map((item) => [item.key, item]));
     return arr2.map((filterItem) => metricMap.get(filterItem.value)).filter((item) => item !== undefined);
 };


export function findItemByTargetId(data, targetId) {
    for (const item of data) {
         if (item.id === targetId) {
              return item;
         }
         if (item.children) {
              const nestedItem = findItemByTargetId(item.children, targetId);
              if (nestedItem) {
                   return nestedItem;
              }
         }
    }
    return null;
}

export function findLongestValue(data) {
    const longestValues = {};

    data.forEach((item) => {
         Object.keys(item).forEach((key) => {
              const value = item[key];
              if (typeof value === 'string') {
                   if (!longestValues[key] || value.length > longestValues[key].length) {
                        longestValues[key] = value;
                   }
              }
         });
    });

    return longestValues;
}

export const filterConversionNameItem = (data, conversionName, segments) => {
    const filterDefaultItems = [];
    const otherItems = [];
    const eCommerceItems = [];
    data.forEach((item) => {
         if (conversionName.find((conversion) => item.label === conversion.label)) {
              if (item.label.includes('Ecommerce')) {
                   eCommerceItems.push(item);
              } else {
                   otherItems.push(item);
              }
         } else {
          if (segments.length) { 
               segments.map((seg)=> filterDefaultItems.push({
                    "label": item.label,
                    "value": `${getSegmentField(seg.id, item.value.split('_').pop())}`
                }))
          } else {
               filterDefaultItems.push(item);
          }
         }
    });

    return [filterDefaultItems, otherItems, eCommerceItems];
};

export const convertNameSegmentSort = ({sort, listSegmentReportApply}) => {
     if (sort && sort.fieldName ) {
          let field = sort.fieldName.split('_');
          if (listSegmentReportApply.find((seg)=> camelCase(seg.id) === field[0])){
               field = field.filter((el)=> el !== field[0])
          }
          return field.join('_')
     }
}

export const changeSortSegment = ({sort, listSegmentReportApply, filterMetric}) => {
     if (sort && sort.fieldName) {
          const segment = listSegmentReportApply.find((seg)=> sort.fieldName.includes(camelCase(seg.id)));
          let field = filterMetric[0].value.split('_') || [];
          if(segment) {
                field.unshift(camelCase(segment.id));
          }
          return field.join('_');
     } else {
          const segment = listSegmentReportApply[0];
          let field = filterMetric[0].value.split('_') || [];
          if(segment) {
                field.unshift(camelCase(segment.id));
          }
          return field.join('_');
     }
}

export function formatNumberWithCommas(number) {
     if(number === null ){
          return '0'
     }
     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') !== 'NaN' ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'None';
}

export function isNumber(value) {
     return !isNaN(Number(value));
}

export function formatNumber(value) {
    if (!isNumber(value)) {
         return String(value);
    } else {
         return formatNumberWithCommas(typeof value === 'number' ? Number(value) : value);
    }
}

export const getCustomPropertiesReport = (customProperties, newProperties) => {
     let newCustomProperties = customProperties.filter((el) => newProperties.find((item) => item === el.properties)) || [];
     if (newCustomProperties && newCustomProperties.length > 0) {
          newCustomProperties = newCustomProperties.map((item) => {
               return {
                    pathPropertyName: item.pathPropertyName,
                    properties: item.properties,
                    target: item.target,
                    bigQueryName: item.bigQueryName,
                    id: item.id,
                    dataSource: item.dataSource,
                    name: item.name,
                    eventObjectName: item.eventObjectName,
                    isCustom: item.isCustom,
               };
          });
     }
     return newCustomProperties;
};
