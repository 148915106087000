import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { CButton, CCard } from '@coreui/react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import MultiFormsInventory from './MultiFormsInventory';
import FormInventoryTableRow from './FormInventoryTableRow';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { API_CLIENT_PERSONAL_DATA, API_CLIENT_UPDATE_FORM_CATEGORIES } from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import { toastError, useOutsideHandling } from '../../../../../../utils';
import {
     setFormRequiringAction,
     handleFetchCountFormsInventory,
     setFormInventoryEdit,
     setFormInventory,
     handleFetchCountFormsRequiring,
     handleFetchCountFormsIgnored,
     setAllFormsInventory,
     setSubscriberState,
     setShowIgnoredInventoryForm,
     setAllFormsRequiring,
     handleUpdateChildFormsInventory,
     setFormIgnored,
     setFormRequiringEdit,
     setInitialPersonalData,
     setFormsDraft,
     handleFetchFormsInventory,
     setListFormsChild,
} from '../../../../../../actions/subscriber';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import FormFilter from './FormFilter';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../../migration/CInput';

const useDraftForms = ({ formsInventory }) => {
     const dispatch = useDispatch();
     const formInventoryEdit = useSelector((state) => state.subscriber.formInventoryEdit);

     // Push draft forms to formInventoryEdit to save changes
     useEffect(() => {
          if (formsInventory && formsInventory.length > 0) {
               const newFormInventoryEdit = [...formInventoryEdit];

               formsInventory.forEach((item) => {
                    if (item.issue === 'isDrafted' && formInventoryEdit.every((el) => el.id !== item.id)) {
                         newFormInventoryEdit.push(item);
                    }
               });

               if (newFormInventoryEdit.length !== formInventoryEdit.length) {
                    dispatch(setSubscriberState({ formInventoryEdit: newFormInventoryEdit }));
               }
          }
     }, [formsInventory]); // eslint-disable-line
};

const FormInventoryLayout = ({ className = '', tableClassName = '', formsInventory, onLoadMore, onClickShowAll }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const formInventory = useSelector((state) => state.subscriber.formInventory);
     const allFormsInventory = useSelector((state) => state.subscriber.allFormsInventory);
     const formInventoryEdit = useSelector((state) => state.subscriber.formInventoryEdit);
     const inventoryShowPaging = useSelector((state) => state.subscriber.inventoryShowPaging);
     const inventoryCountLoading = useSelector((state) => state.subscriber.inventoryCountLoading);
     const countFormInventory = useSelector((state) => state.subscriber.countFormInventory);
     const countFormRequiring = useSelector((state) => state.subscriber.countFormRequiring);
     const inventoryFormsLoading = useSelector((state) => state.subscriber.inventoryFormsLoading);
     const inventoryNumberPerpage = useSelector((state) => state.subscriber.inventoryNumberPerpage);
     const inventoryDisableNextPage = useSelector((state) => state.subscriber.inventoryDisableNextPage);
     const isLoading = useSelector((state) => state.subscriber.inventoryLoading);
     const showIgnoredInventoryForm = useSelector((state) => state.subscriber.showIgnoredInventoryForm);
     const initialPersonalData = useSelector((state) => state.subscriber.initialPersonalData);
     const filterFormCategories = useSelector((state) => state.subscriber.filterFormCategories);
     const formCategoriesRules = useSelector((state) => state.subscriber.formCategoriesRules);
     const [submitLoading, setSubmitLoading] = useState(false);
     const [listAccordionShow, setListAccordionShow] = useState();
     const [tooltipTimestamps, setTooltipTimestamps] = useState(false);
     const [tooltipPeople, setTooltipPeople] = useState(false);
     const [updateModal, setUpdateModal] = useState(false); // false: not show
     const [errors, setErrors] = useState([]);
     const itemsPerPage = 12;
     const [tooltipSettings, setTooltipSettings] = useState(false);
     const [showTooltip, setShowTooltip] = useState(false);
     const statusRefSettings = useRef(null);
     useDraftForms({ formsInventory });

     const rulesDefault = formCategoriesRules ? formCategoriesRules.filter((rule) => rule.name === 'Categorize Forms') : [];

     const treeFamilyOfForms = formsInventory
          ? formsInventory.map((form) => {
                 const formExisted = formInventoryEdit.find((item) => item.id === form.id);

                 if (formExisted) {
                      return { ...form, ...formExisted };
                 }
                 return form;
            })
          : [];
     const statusRefTimestamps = useRef(null);
     const statusRefPeople = useRef(null);
     let formsEdit = formsInventory && formsInventory.every((el) => el.issue !== 'isDrafted'); // Enable save changes button if has at least 1 draft form

     const fetchPersonalData = () => {
          if (!initialPersonalData) {
               callTokenApi(API_CLIENT_PERSONAL_DATA.replace(':accountId', activeAccount.id), 'GET', null)
                    .then((response) => {
                         if (response.status === 200) {
                              const newPersonalData = response.data.personalData;
                              dispatch(setInitialPersonalData(newPersonalData));
                         } else {
                              toastError(response);
                         }
                    })
                    .finally({});
          }
     };
     useEffect(fetchPersonalData, [initialPersonalData]); // eslint-disable-line

     // If save changes button disabled, then check has any changes
     if (formsEdit) {
          formsEdit =
               formInventoryEdit.length > 0
                    ? formInventoryEdit.every((item) => {
                           if (item.issue === 'isDrafted') {
                                return false;
                           }

                           const initForm = allFormsInventory && allFormsInventory.find((form) => form.id === item.id);

                           if (initForm)
                                return (
                                     item.categoryId === initForm.categoryId &&
                                     item.inheritParent === initForm.inheritParent &&
                                     item.isRedirect === initForm.isRedirect &&
                                     item.name === initForm.name
                                );
                           return false;
                      })
                    : true;
     }

     const clickOutsideTimestamps = useCallback(() => {
          setTooltipTimestamps(false);
          setTooltipPeople(false);
          if (!showTooltip) {
               setTooltipSettings(false);
          }
     }, [showTooltip]);
     useOutsideHandling(statusRefTimestamps, clickOutsideTimestamps);
     useOutsideHandling(statusRefSettings, clickOutsideTimestamps);
     useOutsideHandling(statusRefPeople, clickOutsideTimestamps);

     const checkValidate = () => {
          let newErrors = [];
          const newForms = formInventoryEdit && formInventoryEdit.length > 0 ? cloneDeep(formInventoryEdit) : cloneDeep(formsInventory);

          for (let index = 0; index < newForms.length; index++) {
               if (newForms[index].categoryId && !newForms[index].name) {
                    newErrors = [...newErrors, { id: newForms[index].id, invalidName: true }];
               } else if (newForms[index].name && !newForms[index].categoryId) {
                    newErrors = [...newErrors, { id: newForms[index].id, invalidCategory: true }];
               } else {
                    newErrors = [...newErrors];
               }
          }

          if (newErrors.length > 0) {
               setErrors(newErrors);
               toast.error('Some fields are in error!');
          } else {
               setUpdateModal(true);
          }
     };

     const onSubmit = () => {
          setSubmitLoading(true);

          for (let i = 0; i < formInventoryEdit.length; i++) {
               const updateIgnored = allFormsInventory.some((form) => form.id === formInventoryEdit[i].parentId && form.issue === 'isIgnored' && formInventoryEdit[i].inheritParent)
               if (
                    !formInventoryEdit[i].categoryId &&
                    !formInventoryEdit[i].name &&
                    formInventoryEdit[i].issue !== 'isIgnored' &&
                    formInventoryEdit[i].issue !== 'isDrafted'
               ) {
                    formInventoryEdit[i].issue = 'isAttention';
               }

               if (formInventoryEdit[i].issue === 'isDrafted') {
                    formInventoryEdit[i].issue = !formInventoryEdit[i].name && !formInventoryEdit[i].categoryId ? 'isAttention' : '';
               }

               if(updateIgnored) {
                    formInventoryEdit[i].issue = 'isIgnored';
               }
          }

          if (formInventoryEdit && formInventoryEdit.length > 0) {
               formInventoryEdit.map((item)=>{
                    if(item.name){
                         item.name = item.name.trim();
                    }

                    //update blank form when inheritedParent is false
                    if (!item.inheritParent &&  item.suggestInherit){
                         item.name = '';
                         item.categoryId = null;
                    }
                    return item;
               });

               const newDataRequest = {
                    accountId: activeAccount.id,
                    data: formInventoryEdit,
                    type: 'inventory',
               };
               callTokenApi(API_CLIENT_UPDATE_FORM_CATEGORIES, 'PUT', newDataRequest)
                    .then((response) => {
                         if (response.status === 200) {
                              // toast.success(response.data.message);
                              const { countChildInventory } = response.data
                              if (filterFormCategories && filterFormCategories.length > 0) {
                                   dispatch(handleFetchFormsInventory(20, false, 0, filterFormCategories));
                              } else {
                                   const newData = [];
                                   formsInventory
                                        && formsInventory.forEach((form) => {
                                             const formExisted = formInventoryEdit.find((item) => item.id === form.id);
                                             const childIgnored = formInventoryEdit.find((formEdit) => !formEdit.hasChild && formEdit.parentId === form.id && formEdit.inheritParent && formEdit.issue === 'isIgnored');
                                             if(!childIgnored || !countChildInventory.some((child) => child.id === childIgnored.parentId && child.count === 0)) {
                                                  let newForm = form
                                                  if (formExisted) {
                                                       newForm = { ...form, ...formExisted };
                                                  }

                                                  newData.push(newForm);
                                             } 
                                   });
                                   dispatch(handleUpdateChildFormsInventory());
                                   if (className === 'inventory') {
                                        dispatch(setFormInventory(newData));
                                        const newAllFormInventory = allFormsInventory.map((form) => {
                                             const formExisted = formInventoryEdit.find((item) => item.id === form.id);

                                             if (formExisted) {
                                                  return { ...form, ...formExisted };
                                             }

                                             dispatch(setSubscriberState({ [`requiring${form.id}`]: undefined }));
                                             return form;
                                        });
                                        dispatch(setAllFormsInventory(newAllFormInventory));
                                   } else {
                                        dispatch(setAllFormsInventory(newData));
                                        const newDataFormInventory = formInventory.map((form) => {
                                             const formExisted = formInventoryEdit.find((item) => item.id === form.id);

                                             if (formExisted) {
                                                  return { ...form, ...formExisted };
                                             }
                                             return form;
                                        });
                                        dispatch(setFormInventory(newDataFormInventory));
                                   }
                              }

                              formInventoryEdit.forEach((form) => {
                                   if(form.hasChild) {
                                        dispatch(
                                             setListFormsChild({
                                                  [`requiring${form.id}`]: [],
                                                  [`numberOffset${form.id}`]: 0,
                                             })
                                        );
                                   }
                              })

                              // reset form requiring
                              dispatch(setFormRequiringAction(null));
                              dispatch(setAllFormsRequiring(null));
                              dispatch(setFormRequiringEdit(null));
                              dispatch(setFormInventoryEdit(null));
                              dispatch(setFormRequiringEdit(null));
                              dispatch(setFormsDraft(null));
                              dispatch(setFormIgnored(null));
                              dispatch(handleFetchCountFormsRequiring());
                              dispatch(handleFetchCountFormsIgnored(null, activeAccount));
                              dispatch(handleFetchCountFormsInventory(null, activeAccount, filterFormCategories));

                              if (rulesDefault && rulesDefault.length === 0) {
                                   dispatch(setSubscriberState({ formCategoriesRules: null }));
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         setUpdateModal(false);
                         setSubmitLoading(false);
                    });
          }
     };

     const handleClickBubbleTimestamps = () => {
          setTimeout(() => {
               if (!tooltipTimestamps) {
                    setTooltipTimestamps(true);
               }
          }, 100);
     };

     const handleClickBubbleSettings = () => {
          setTimeout(() => {
               if (!tooltipSettings) {
                    setTooltipSettings(true);
               }
          }, 100);
     };

     const handleClickBubblePeople = () => {
          setTimeout(() => {
               if (!tooltipPeople) {
                    setTooltipPeople(true);
               }
          }, 100);
     };

     const handleShowIgnoredForm = () => {
          dispatch(setShowIgnoredInventoryForm(!showIgnoredInventoryForm));
     };

     const tabOverlay =
          className === 'inventory' &&
          treeFamilyOfForms.length >= itemsPerPage &&
          (countFormInventory > itemsPerPage || countFormRequiring > itemsPerPage);

     return (
          <>
               <div className="title-form-action form-inventory-header pb-2">
                    <p className="mb-0">
                         We’ve found and are Listening to {inventoryCountLoading ? '...' : countFormInventory}{' '}
                         {+countFormInventory === 1 ? ' form' : ' forms'} on your website. Make sure all of them are categorized below based on the
                         purpose of the forms.
                    </p>
                    <div className="form-action-settings">
                         <div className="checkbox-header checkbox-ignored-form">
                              <CFormGroup variant="custom-checkbox" inline>
                                   <CInputCheckbox
                                        custom
                                        id={'ignoredForm'}
                                        name={'ignoredForm'}
                                        checked={!!showIgnoredInventoryForm}
                                        onChange={handleShowIgnoredForm}
                                   />
                                   <CLabel variant="custom-checkbox" htmlFor={'ignoredForm'}>
                                        Show only ignored forms
                                   </CLabel>
                              </CFormGroup>
                         </div>

                         <div className="checkbox-header checkbox-settings">
                              <CButton className="p-0" onClick={handleClickBubbleSettings}>
                                   <CIcon icon="icon-filter" width={25} className="mr-2" />
                                   <span>Filter</span>
                              </CButton>
                              {tooltipSettings && (
                                   <CCard className="block-tooltip reporting-values-tooltip form-filter-tooltip" ref={statusRefSettings}>
                                        <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                        <div className="block-tooltip-text">
                                             <FormFilter setShowTooltip={setShowTooltip} />
                                        </div>
                                   </CCard>
                              )}
                         </div>
                    </div>
               </div>
               <div
                    className={`table-layout table-inventory ${tableClassName} ${
                         (formsInventory && formsInventory.length === 0) || isLoading ? 'no-form-found' : ''
                    }`}
               >
                    <div className="css-table-wrap">
                         <div className={`css-table ${tabOverlay && !isLoading ? 'table-overlay' : ''}`}>
                              {className === 'viewAllInventory' && inventoryFormsLoading && (
                                   <div className="table-forms-loading-overlay">
                                        <CenterSpinner />
                                   </div>
                              )}
                              <div className="css-table-header">
                                   <div className="listener-name">Listener Name</div>
                                   <div className="form-id">Form ID</div>
                                   <div
                                        className={`form-location ${initialPersonalData && initialPersonalData.status ? 'form-location-people' : ''}`}
                                   >
                                        {initialPersonalData && initialPersonalData.status ? 'Location' : 'Form Location'}
                                   </div>
                                   <div className="take-action">Category</div>
                                   <div className="form-name">Form Name</div>
                                   <div></div>
                                   <div className="btn-suggest"></div>
                                   <div className={`${!showIgnoredInventoryForm ? 'inherit-inventory' : ''}`}></div>
                                   {initialPersonalData && initialPersonalData.status && (
                                        <div className="form-location form-people">
                                             <span>Block Reveal</span>
                                             {((formsInventory && formsInventory.length > 0) || !isLoading) && (
                                                  <div className="icon-tooltip-block">
                                                       <CIcon icon="icon-info" className="ml-2" cursor="pointer" onClick={handleClickBubblePeople} />
                                                       {tooltipPeople && (
                                                            <CCard
                                                                 className="block-tooltip reporting-values-tooltip redirect"
                                                                 ref={statusRefPeople}
                                                            >
                                                                 <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                                                 <div className="block-tooltip-text">
                                                                      <p>
                                                                           Turn this on to block ListenLayer from identifying people when they fill
                                                                           out this form.
                                                                      </p>
                                                                 </div>
                                                            </CCard>
                                                       )}
                                                  </div>
                                             )}
                                        </div>
                                   )}
                                   <div className="redirects form-people">
                                        <span>Redirects</span>
                                        {((formsInventory && formsInventory.length > 0) || !isLoading) && (
                                             <div className="icon-tooltip-block">
                                                  <CIcon icon="icon-info" className="ml-2" cursor="pointer" onClick={handleClickBubbleTimestamps} />
                                                  {tooltipTimestamps && (
                                                       <CCard
                                                            className="block-tooltip reporting-values-tooltip redirect"
                                                            ref={statusRefTimestamps}
                                                       >
                                                            <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                                            <div className="block-tooltip-text">
                                                                 <p>
                                                                      Turn this on for any form that redirects to a thank you page. This will improve
                                                                      the accuracy of your tracking.
                                                                 </p>
                                                            </div>
                                                       </CCard>
                                                  )}
                                             </div>
                                        )}
                                   </div>
                              </div>

                              {!isLoading && formsInventory ? (
                                   treeFamilyOfForms.length > 0 ? (
                                        <>
                                             {treeFamilyOfForms.map((item, index) => {
                                                  const hasChild = item.hasChild;

                                                  return (
                                                       <React.Fragment key={`inventory-${item.id}`}>
                                                            {hasChild ? (
                                                                 <MultiFormsInventory
                                                                      className={className}
                                                                      index={index}
                                                                      formsData={item}
                                                                      listAccordionShow={listAccordionShow}
                                                                      setListAccordionShow={setListAccordionShow}
                                                                      errors={errors}
                                                                      setErrors={setErrors}
                                                                 />
                                                            ) : (
                                                                 <FormInventoryTableRow
                                                                      className={className}
                                                                      data={item}
                                                                      redirect={true}
                                                                      index={index}
                                                                      errors={errors}
                                                                      setErrors={setErrors}
                                                                 />
                                                            )}
                                                       </React.Fragment>
                                                  );
                                             })}
                                        </>
                                   ) : (
                                        <div className="table-body-row">
                                             <p>No form found!</p>
                                        </div>
                                   )
                              ) : (
                                   <CenterSpinner />
                              )}
                         </div>
                    </div>

                    {!isLoading && formsInventory && (
                         <>
                              {className === 'viewAllInventory' && treeFamilyOfForms.length > 0 && inventoryShowPaging && (
                                   <div className="text-center forms-paging">
                                        <CButton
                                             className="btn-paging-forms btn-prev"
                                             disabled={isLoading || inventoryFormsLoading || inventoryNumberPerpage < 1}
                                             onClick={() => onLoadMore(inventoryNumberPerpage - 1)}
                                        >
                                             {'< Prev'}
                                        </CButton>
                                        <CButton
                                             className="btn-paging-forms btn-next"
                                             disabled={isLoading || inventoryFormsLoading || inventoryDisableNextPage}
                                             onClick={() => onLoadMore(inventoryNumberPerpage + 1)}
                                        >
                                             {'Next >'}
                                        </CButton>
                                   </div>
                              )}

                              {tabOverlay && (
                                   <CButton className="btn-show-all" onClick={onClickShowAll}>
                                        See all {inventoryCountLoading ? '...' : countFormInventory} forms
                                        <CIcon className="icon-arrow-bar" icon="iconChevronRight" width={6} height={7} />
                                   </CButton>
                              )}

                              {treeFamilyOfForms.length > 0 && (
                                   <div className="save-changes-button-wrapper">
                                        <CButton color="primary" disabled={submitLoading || formsEdit} onClick={checkValidate}>
                                             {submitLoading ? <span className="dots-waiting">Waiting</span> : 'Save Changes'}
                                        </CButton>
                                   </div>
                              )}
                         </>
                    )}
               </div>

               <ConfirmSaveChange
                    show={updateModal}
                    onClose={() => setUpdateModal(false)}
                    isLoading={submitLoading}
                    onAccept={() => onSubmit()}
                    title="Are you sure you want to save?"
               ></ConfirmSaveChange>
          </>
     );
};

export default FormInventoryLayout;
